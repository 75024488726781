<div class="dftWrapper wrapperMerci">
  <!-- <cookie-dlg></cookie-dlg> -->
  <div class="wbg column centercenter">
  </div>
  <div class="merciWrapperMerci row centercenter ">

    <div class="titleMerci row centercenter">MERCI
      <img class="imgavionmerci" src="../../../assets/pictos/ethique/comm/avion.png" width="110px" height="56px"
        alt="avion">
    </div>
  </div>


  <div class="imgmerci_small row centercenter">

    <img src="../../../assets/photos/merci/merci.webp" width="200px" height="214px" alt="merci">
  </div>

  <div class="ctnMerci">
    <img width="1024px" height="521px" class="imgmerci_big" src="../../assets/photos/merci/merci_w1024.webp" alt="merci"
      srcset="
  assets/photos/merci/merci_w960.webp 960w,
  assets/photos/merci/merci_w1024.webp 1024w,
  assets/photos/merci/merci_w1280.webp 1280w,
  assets/photos/merci/merci_w1763.webp 1763w" sizes="(min-width: 1228px) 1228px, 100vw">
    <div class="bienrecuTexteMerci column">
      <div class="brtmtext column centerstart selfcenter">
        <h1>Nous avons bien&nbsp;reçu votre&nbsp;demande&nbsp;!</h1>
        <h1>Nous reviendrons vers&nbsp;vous au&nbsp;plus&nbsp;vite.</h1>
      </div>

      <div class="btnLinkMerci rowwrap centercenter">
        <div class="MysButtonMerci ">
          <gso-button-ripple bgColorLtR bgColor='transparent' bgColorHover='#FE4D18' fontColorHover="white"
            itext="Retour à l'accueil" [routerLink]="['/accueil']"></gso-button-ripple>
        </div>
        <!-- <div class="contactcontainer">
      <div class="contactLink" ><div [routerLink]="['/studio']"> En savoir plus sur nous &nbsp;&nbsp;></div></div>
    </div> -->
      </div>
    </div>
  </div>
</div>
