import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import {gsap} from 'gsap';

@Directive({
  selector: '[hlEffect]'
})
export class HlEffectDirective {


  constructor(
    private elementRef:ElementRef,
    private renderer:Renderer2) { }

    ngOnInit(){
    //  this.color = this.defaultColor;
    }


    @Input() defaultColor='green';
    @Input() highlight= 'lime';

   // @HostBinding('style.color') color:string = this.defaultColor;


    @HostListener('mouseenter') mouseover(){
      //this.renderer.setStyle(this.elementRef.nativeElement, 'background-color', 'blue');

      gsap.set(this.elementRef.nativeElement, {color:this.highlight, x:10})
      //this.color=this.highlight;
    }

    @HostListener('mouseleave') mouseleave(){
      //this.renderer.setStyle(this.elementRef.nativeElement, 'background-color', 'transparent');
      //this.color=this.defaultColor;

      gsap.set(this.elementRef.nativeElement, {clearProps:'color', x:0})

    }

}
