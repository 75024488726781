// import { firstValueFrom } from 'rxjs';
// import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// import  configJson from '../../assets/data/config.json';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private configJson: any=null;

  get():Promise<any>
  {

    // let config:any;
    // console.log('configjson:',configJson)
    const configPath = '../../assets/data/config.json';

     return new Promise<string> ((res, err) =>{

      if (this.configJson)
        res(this.configJson)
      else
      {
      fetch(configPath).then(res => res.json())
     .then(
       data => {  this.configJson=data;
        // console.log('data:',configJson);
                     res(data);

                      },
       error=> {
            console.log('fichier config.json non trouvé', error)
             if (error.error.message && error.error.message!='')
               err(error.error.message);
             else
               err(error);
                       })
      }
      }
     );

  }


}
