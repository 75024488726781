
   <header ></header>

   <main >
    <gso-to-the-top #ttt right="2px" bottom="10%"></gso-to-the-top>
<cookie-dlg [closepopup]=cookieClose></cookie-dlg>
   <div id="smooth-wrapper">
      <div id="smooth-content">
      <router-outlet></router-outlet>
     <!-- <footer *ngIf="displayFooterOnthisPage"></footer> -->
      </div>
         </div>
  </main>
