import { MenuComponent } from './../menu/menu.component';
import { GsoLibService } from '../_services/gso-lib-service.service';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';

import { Router, Event, NavigationStart, NavigationEnd } from '@angular/router';
import gsap from 'gsap';
import { Subscription } from 'rxjs';
import { MorphSVGPlugin } from 'gsap/MorphSVGPlugin';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('headercontainer') headercontainer!: ElementRef;
  @ViewChild(MenuComponent) menu!: MenuComponent;
  @ViewChild('headerbigbutton') headerbigbutton!: ElementRef;
  @ViewChild('headersmallbutton') headersmallbutton!: ElementRef;

  tlLoader!: GSAPTimeline;
  subNavigationEventAfterViewInit!: Subscription;

  menubottomright = '0';

  displayFlechemenu = true;

  constructor(private router: Router, private libserv: GsoLibService) {}

  // utilisé pour lancer le popup contact/devis sur un appel de la landing page
  message = '';
  subMessageString: Subscription = new Subscription();
  subNavigationEventOnInit: Subscription = new Subscription();

  bgLanding = true;

  ngOnInit(): void {
    this.subNavigationEventOnInit = this.router.events.subscribe(
      (event: Event) => {
        if (event instanceof NavigationEnd) {
          if (this.smoother) {
            this.libserv.createSmoother();
            this.smoother.scrollTo(0, false);
          }
          if (event.url == '/accueil' || event.url == '/') {
            // gsap.to(this.headersmallbutton.nativeElement, { autoAlpha: 1 });

          //  gsap.set(['#textemenu','#flechemenu'], {display:'block'})
            this.bgLanding = true;

            gsap.matchMediaRefresh(); // pour cacher le header quand on est sur la page d'accueil
            // avec width > $sm
          } else {

        gsap.set(['#textemenu','#flechemenu'], {display:'none'})
            // si on est sur la page projet
            // on cache le bouton "débuter un projet"
            if (event.url == '/projet') {
              gsap.set(
                [
                  this.headerbigbutton.nativeElement,
                  this.headersmallbutton.nativeElement,
                ],
                { autoAlpha: 0 }
              );
            } else {
              // si on n'est pas sur la page projet
              // on affiche le bouton "débuter un projet"
              gsap.to(
                [
                  this.headerbigbutton.nativeElement,
                  this.headersmallbutton.nativeElement,
                ],
                { autoAlpha: 1 }
              );
            }

            this.bgLanding = false;
            gsap.set('.headerwhite', { autoAlpha: 1 });
          }
        } else if (event instanceof NavigationStart) {
          this.libserv.killSmoother();
        }
      }
    );
  }

  railwayLink = '';
  railwayText = '';
  smoother: any;
  isWindowSmall = false;

  ngAfterViewInit() {
    gsap.registerPlugin(MorphSVGPlugin);
    MorphSVGPlugin.convertToPath('#barre1');
    if (this.smoother) {
      this.libserv.killSmoother();
    }
    this.smoother = this.libserv.createSmoother();

    const mm = gsap.matchMedia();

    mm.add('(max-width: ' + 600 + 'px)', () => {
      this.isWindowSmall = true;
      if (this.router.url == '/accueil' || this.router.url == '/') {
        gsap.set('.headerbg', { backgroundColor: 'transparent' });
        gsap.set('.headerwhite', { autoAlpha: 1 });

      }

      gsap.set(['#textemenu','#flechemenu'], {display:'none', autoAlpha:0})
    });
    mm.add('(min-width: ' + 600 + 'px)', () => {
      this.isWindowSmall = false;
    //
      if (this.router.url == '/accueil' || this.router.url == '/') {
        gsap.set('.headerbg', { backgroundColor: 'white' });
        gsap.set('.headerwhite', { autoAlpha: 0 });

      }

    });

    // ANIMATION Changement burger en croix
    this.tlChangeBurgerToCross
      .to('#barrejaune', {
        rotate: '15deg',
        transformOrigin: 'center',
        y: '-=45px',
      })
      .to(
        '#barrebleue',
        { rotate: '105deg', transformOrigin: 'center', y: '+=40px' },
        '0'
      )
      .to('#rond', { autoAlpha: 0 }, '<');

    // ANIMATION du logo
    this.logoenter();

    this.subNavigationEventAfterViewInit = this.router.events.subscribe(
      (event: Event) => {
        if (event instanceof NavigationEnd) {
          this.railwayLink = event.url;

          gsap.set('.railway', { display: 'flex' });
          gsap.set('.railway', { autoAlpha: 1 });

          switch (event.url) {
            case '/mentions': {
              this.railwayText = 'Mentions légales';
              break;
            }
            case '/cgu': {
              this.railwayText = "Conditions générales d'utilisation";
              break;
            }
            case '/methode': {
              this.railwayText = 'Méthode';
              break;
            }
            case '/contact': {
              this.railwayText = 'Contact';
              break;
            }
            case '/solutions': {
              this.railwayText = 'Solutions';
              break;
            }
            case '/ethique': {
              this.railwayText = 'Ethique';
              break;
            }
            case '/studio': {
              this.railwayText = 'Studio';
              break;
            }
            case '/projet': {
              this.railwayText = 'Débuter un projet';
              break;
            }
            case '/merci': {
              this.railwayText = 'Débuter un projet';
              break;
            }
            default: {
              gsap.set('.railway', { display: 'none' });
              break;
            }
          }
        }

        // lors d'un changement de page, on ferme le menu
        if (event instanceof NavigationStart) {
          this.closeMenuIfOpenned();
        }
      }
    );

    // utilisé pour effectuer une action sur un appel de page
    this.subMessageString = this.libserv.currentMessage.subscribe((message) => {
      // on ferme le menu
      if (message == 'closeMenu') this.onCloseMenu();

      // on est ailleur que sur la page d'accueil
      if (message == 'headerBgFullWidth') this.onBgFullWidth();

      // on est sur la page d'accueil
      if (message == 'headerBgLanding') this.onBgLanding();

      // ouverture du menu
      if (message == 'headerBgMenu') this.onBgMenu();

      // pour recevoir les coordonnées du polygone d'ouverture du menu
      if (message.includes('menubottomright', 0)) {
        setTimeout(() => {
          this.menubottomright = message.slice(15);
        });
      }

      if (message == 'initlagfooter') this.initLagFooter();
    });
  }



  makeyoursAnimRunning = false;
  firstTime = true;
  tlMakeYoursAnim = gsap.timeline();

  logoenter() {
    if (!this.makeyoursAnimRunning) {
      this.tlMakeYoursAnim = gsap.timeline({
        onStart: () => {
          this.makeyoursAnimRunning = true;
        },
        onComplete: () => {
          this.makeyoursAnimRunning = false;
          gsap.set('.burgerContainer', { clearProps: 'backgroundColor' });
          gsap.set('#rond', { zIndex: '-1' });
          gsap.to('.menutext', {autoAlpha:1});

        },
      });
      this.tlMakeYoursAnim
        .set('.burgerContainer', { backgroundColor: 'white' })
        .set('#flechemenu', {autoAlpha:0})
        .set('#textemenu', {autoAlpha:0})
        .fromTo('#make', { autoAlpha: 0, x: 50 },{ autoAlpha: 1, x: 50, duration: 0.5, ease: 'power1.in' })
        .to('#make', { x: 0 })
        .fromTo('#yours', { x: -500, duration: 0.4, zIndex: -1 },{ zIndex: -1, x: 0, ease: 'power4.out', duration: 1 },'-=0.6');

      if (this.firstTime) {
        this.tlMakeYoursAnim
          .to('#barrejaune', {rotate: '65deg',transformOrigin: 'left', ease: 'bounce' })
          .to('#barrebleue', {x: '+=25px', rotate: '65deg', transformOrigin: 'right', ease: 'bounce'}, '<' )
          .to('#barrejaune', { y: '+=18px' }, '<')
          .to('#barrebleue', { y: '+=18px' }, '<')
          .to( '#rond', { x: '+=230px', duration: 1.5, ease: 'power4' },'-=0.3' )
          .to('#flechemenu', {autoAlpha:1},'-=1.1' )
          .to('#textemenu', {autoAlpha:1},'-=1.3' )
        this.firstTime = false;
      }
    }
  }

  ngOnDestroy() {
    this.libserv.killSmoother();
    this.subMessageString.unsubscribe();
    this.subNavigationEventAfterViewInit.unsubscribe();
    this.subNavigationEventOnInit.unsubscribe();
  }

  closeMenuIfOpenned() {
    if (this.isMenuOpenned) {
      this.menu.showOverMenu(false);
      this.onCloseMenu();

      this.menuOpenned = false;
    }
  }

  initLagFooter() {
    this.smoother.effects('.imgboxFooter', { lag: 0.02 });
  }

  menuOpenned = false;
  // ouverture du menu
  onBgMenu() {
    // on cache le logo, affiche bouton "debuter", supprime scrollbars extérieures
    gsap.to('.logowrapper', { autoAlpha: 0 });

    gsap.to('body', { minWidth: 'none', overflow: 'hidden' });

    this.menuOpenned = true;
    this.libserv.changeMessage('hideToTheTop');
  }

  // sur la page d'accueil
  onBgLanding() {
    // on cache le bandeau blanc du header, supprime scrollbars extérieures
    gsap.set('.headerwhite', { backgroundColor: 'white' });
    gsap.set('body', { minWidth: 'none', overflow: 'hidden' });
    gsap.set([this.headerbigbutton.nativeElement,this.headersmallbutton.nativeElement], { autoAlpha: 0 });
  }

  // on n'est pas sur la page d'accueil
  onBgFullWidth() {
    // on affiche le bandeau blanc et on remet les scrollbars extérieures
    gsap.set('.headerwhite', { backgroundColor: 'white' });
    gsap.set('body', { minWidth: 'auto', overflow: 'auto' });
  }

  isMenuOpenned = false;

  tlChangeBurgerToCross: GSAPTimeline = gsap.timeline({ paused: true });
  onClickBurger() {
    // si menu fermé
    if (!this.isMenuOpenned) {
      if (this.makeyoursAnimRunning) this.tlMakeYoursAnim.progress(1, false);

      if (!this.makeyoursAnimRunning)
        this.tlChangeBurgerToCross.restart();

      this.isMenuOpenned = true;
      gsap.to(
        [
          this.headerbigbutton.nativeElement,
          this.headersmallbutton.nativeElement,
        ],
        { autoAlpha: 1 }

      );
      gsap.set(['#textemenu','#flechemenu'], {display:'none'});
      this.displayFlechemenu=false;
    }

    // si menu ouvert on le ferme
    else {
      // fonction car aussi appelé dans le menu quand on clique sur un lien
      this.onCloseMenu();
      this.isMenuOpenned = false;

        // gsap.set(['#textemenu','#flechemenu'], {display:'block'})
    }
    this.menu.showOverMenu();
  }

  onCloseMenu() {
    this.tlChangeBurgerToCross.reverse();

    // animation du logo
    gsap.to('.logowrapper', { autoAlpha: 1, ease: 'power1.in' });

    // on remet les scrollbars si on n'est pas sur la page d'accueil
    if (!this.bgLanding)
      gsap.to('body', { minWidth: 'auto', overflow: 'auto' });

      // on cache le bouton débuter un projet sur la page d'accueil et la page projet
      if ((this.bgLanding) || this.router.url == '/projet')
        gsap.set([this.headerbigbutton.nativeElement,
          this.headersmallbutton.nativeElement], { autoAlpha: 0 });


    this.libserv.changeMessage('showToTheTop');
    this.isMenuOpenned = false;
  }
  updated = true;
}
