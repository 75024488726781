<!-- ////////////////////////// -->
<!-- MENU  -->
<!-- ////////////////////////// -->

<div class="menuwrapper">


  <!-- HEADER du menu -->
  <div class="menuhello">hello</div>


  <!-- TITRES du menu -->
  <div class="menutitlesreseauxwrapper row startcenter">
    <div class="menutitlescontainer">
     <h1   class="menutitle row startcenter " (click)="showOverMenu(false)" [routerLink]="['/solutions']">
      <span #title (mouseleave)="onLeaveTitle($event)" (mouseenter)="onEnterTitle($event)" class='h1text'>Solutions</span></h1>
      <h4>Ce que nous faisons</h4>
    <h1  class="menutitle  row startcenter "  (click)="showOverMenu(false)"  [routerLink]="['/methode']">
      <span #title (mouseleave)="onLeaveTitle($event)" (mouseenter)="onEnterTitle($event)" class='h1text'>Méthode</span></h1>
      <h4>Comment nous travaillons</h4>
    <h1  class="menutitle  row startcenter "  (click)="showOverMenu(false)"  [routerLink]="['/ethique']" >
      <span #title (mouseleave)="onLeaveTitle($event)" (mouseenter)="onEnterTitle($event)" class='h1text'>Ethique</span></h1>
      <h4><div class="whiteFillSubMenu"></div>Notre démarche éco-responsable</h4>
    <h1  class="menutitle  row startcenter "  (click)="showOverMenu(false)"  [routerLink]="['/studio']" >
      <span #title (mouseleave)="onLeaveTitle($event)" (mouseenter)="onEnterTitle($event)" class='h1text'>Studio</span></h1>
      <h4>Tout sur nous</h4>
    <!-- <h1  class="menutitle  row startcenter "  (click)="showOverMenu(false)"  [routerLink]="['/projets']" >
        <span #title (mouseleave)="onLeaveTitle($event)" (mouseenter)="onEnterTitle($event)" class='h1text'>Projets</span></h1> -->
    </div>


    <!-- ICONES réseaux sociaux du menu -->
    <div class="menureseaux column centercenter">
      <a href="https://www.linkedin.com/company/bymakeyours" target="_blank"><img height="25px" width="31px"  src="../../assets/pictos/linkedin_60.webp" alt="linkedin"></a>
      <a href="https://twitter.com/byMakeYours" target="_blank"><img height="25px" width="30.6px"  src="../../assets/pictos/twitter_60.webp" alt="twitter"></a>
      <a href="https://instagram.com/bymakeyours" target="_blank"> <img height="25px" width="24px" src="../../assets/pictos/instagram_60.webp" alt="instagram"></a>
      <a href="https://www.facebook.com/bymakeyours" target="_blank"><img height="25px" width="13.3px"  src="../../assets/pictos/facebook_60.webp" alt="facebook"></a>
      <a href="https://www.tiktok.com/@bymakeyours" target="_blank"><img height="25px" width="22px"  src="../../assets/pictos/tiktok_60.webp" alt="tiktok"></a>
    </div>
  </div>


    <div class="menucontact column" (click)="showOverMenu(false)" [routerLink]="['/contact']" routerLinkActive="router-link-active">
      <div class="textconcontactmenu">Con- &nbsp;Con-</div>
      <div class="texttactcontactmenu">tact &nbsp;&nbsp;tact</div>
    </div>



</div>
