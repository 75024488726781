<div #bgcookie class="bgcookie" *ngIf="closePopup==false"></div>
<div #wrappercookie class="wrapperCookie " *ngIf="closePopup==false">
  <div class="containerTextCookie column centerstart">

    <div class="cookiesTitle row startcenter">
      <h3>Hey ! Vous prendrez bien quelques <span class="bold">Cookies</span> ?</h3>
      <img width="100px" height="109px" src="../../assets/pictos/cookie_chapeau.webp" alt="cookie chapeau">
    </div>
      <p>Ils ne sont pas aussi bons que les vrais cookies, mais ils nous aident
à vous offrir une expérience en ligne des plus délicieuses.</p>
<p>
Vous resterez anonyme,
nos cookies sont uniquement utilisés pour des raisons
de sécurité et de statistiques.</p>
<p>
Si vous êtes gourmands, voici la recette : <a href="/cgu">cliquez-ici</a>&nbsp;!



<br> Pas de noix ni de kilos en trop !
    <div class="accordeonCookie">
      <gso-accordeon titre="détails" [closed]="true">
        <p class="textDetailsCookie"> <span class="bold">Cookies nécessaires</span>
          <br><span class="bold">Nom : </span>XSRF-TOKEN /
          <span class="bold">Fournisseur : </span>Laravel /
          <span class="bold">Expiration :</span> 1 an
          <br><span class="bold">Finalité :</span> Token Laravel pour protection anti CSRF. Cross-Site Request Forgery,
          abrégé CSRF (parfois prononcé sea-surfing en anglais) ou XSRF, est un type de vulnérabilité des services
          d'authentification web. Ce cookie permet de s'en protéger.
          <br><span class="bold">Nom :</span> makeyours_session /
          <span class="bold">Fournisseur :</span> Laravel /
          <span class="bold">Expiration :</span> 1 an
          <br><span class="bold">Finalité :</span> Enregistre un identifiant de session Laravel, utilisé en interne pour
          identifier une session utilisateur.
        </p>
        <p class="textDetailsCookie"><span class="bold">Cookies analytiques</span>
          <br><span class="bold">Nom :</span> _ga /
          <span class="bold">Fournisseur :</span> Google /
          <span class="bold">Expiration :</span> 14 mois
          <br><span class="bold">Finalité :</span> Enregistre un identifiant unique utilisé pour générer des données
          statistiques sur la façon dont le visiteur utilise le site.
          <br><span class="bold">Nom :</span> {{gaId}} / Fournisseur : Google / Expiration : 12 mois
          <br><span class="bold">Finalité :</span> Enregistre un identifiant unique utilisé pour générer des données
          statistiques sur la façon dont le visiteur utilise le site..
        </p>
      </gso-accordeon>
    </div>
  </div>

  <div class="containerButtonsCookie">
    <div class="btnDenyCookie" (click)="deny()">
      Non merci
    </div>
    <div class="btnAcceptCookie" (click)="accept()">
      OK pour moi
    </div>
  </div>

</div>
