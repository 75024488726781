<div class="wrapperfooterbottom column centercenter">
  <div #wrapperfooter class="wrapperfooter rowwrap">
    <div class="imgboxFooter w50 w45 row startcenter centermd"><img (mouseenter)="onenterImgEnsemble()"
        (mouseleave)="onleaveImgEnsemble()" width="364px" height="384px" #imgensemble
        src="../../assets/photos/ensemble.webp" alt="ensemble">
    </div>
    <div class="w50 w55 column centerstart centermd ">
      <h1 class="titlefooter">Ensemble, faisons de vos idées une réalité&nbsp;!</h1>
      <div class="btnlinkfooter rowwrap centercenter ">
        <div class="MysButtonFooter ">
          <gso-button-ripple bgColorLtR bgColor='transparent' bgColorHover='#FE4D18' fontColorHover="white"
            itext="Débuter un projet" [routerLink]="['/projet']"></gso-button-ripple>
        </div>
        <div class="contactcontainer ">
          <div *ngIf="!getRouteActive('/contact')" class="contactLink" row centercenter>
            <div [routerLink]="['/contact']"> Contactez-nous ></div>
          </div>
          <div *ngIf="getRouteActive('/contact')" class="contactLink">
            <div (click)="goToTheTop()"> Contactez-nous ></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bottomfooter ">
    <div class="copyrightfooter">
      <span class="copyrightfooter-left">© Make Yours 2023 | <a class="cgu ul" [routerLink]="['/cgu']">
          <div class="cgu"></div>
        </a> | <a class="ul mentions" [routerLink]="['/mentions']">Mentions légales</a> | Tous
        droits&nbsp;réservés.</span>
      <span class="copyrightfooter-right"> Site&nbsp;
        <span class="boldFont" *ngIf="getRouteActive('/ethique')">éco-conçu</span>
        <a *ngIf="!getRouteActive('/ethique')" class="ul ethique" [routerLink]="['/ethique']"><span class="boldFont">éco-conçu</span></a>
        &nbsp;par&nbsp;
        <span class="boldFont" *ngIf="getRouteActive('/studio')">Make&nbsp;Yours</span>
        <a *ngIf="!getRouteActive('/studio')" class="ul studio" [routerLink]="['/studio']"><span class="boldFont">Make
            Yours</span></a>
      </span>
    </div>
  </div>
</div>
