import { Component, AfterViewInit, ViewChild, ElementRef, Input } from '@angular/core';
import gsap from 'gsap';


@Component({
  selector: 'gso-accordeon',
  templateUrl: './gso-accordeon.component.html',
  styleUrls: ['./gso-accordeon.component.scss']
})
export class GsoAccordeonComponent implements AfterViewInit {

  @Input('titre') titre='';
  @Input('closed') closed=false;

   @ViewChild('submenu') private submenu!: ElementRef;

   @ViewChild('moncontenu') private moncontenu!: ElementRef;

   @ViewChild("plus") plus !: ElementRef;
   @ViewChild("moins") moins !: ElementRef;




  ngAfterViewInit(): void {

    if (this.submenu && !this.closed)
    {
      gsap.set(this.submenu.nativeElement, {autoAlpha:1, height:"auto"});
    }

    if(this.closed)
    {
      gsap.set(this.plus.nativeElement, {autoAlpha:1,rotation:0});
      gsap.set(this.moins.nativeElement, {autoAlpha:0,rotation:-90});
    }
/*
    if(this.closed)
      this.onClick()*/

  }


  onClick()
  {
    if (((this.submenu.nativeElement as HTMLElement).style.height=="0px") ||
    ((this.submenu.nativeElement as HTMLElement).style.height==""))
    {

      gsap.set(this.moncontenu.nativeElement, {y:0});
      gsap.to(this.submenu.nativeElement, {height:"auto",duration:0.6, ease:"expo"});
      gsap.from(this.moncontenu.nativeElement, {y:-100,duration:1, ease:"expo"});
      gsap.to(this.plus.nativeElement, {autoAlpha:0,rotation:90,duration:0.4});
      gsap.to(this.moins.nativeElement, {autoAlpha:1,rotation:0,duration:0.4, onComplete:()=>{this.closed=false;}});
    }
    else
    {
      gsap.to(this.submenu.nativeElement, {height:"0px",duration:0.6, ease:"expo"});
      gsap.to(this.moncontenu.nativeElement, {y:-100,duration:1, ease:"expo"});
      gsap.to(this.plus.nativeElement, {autoAlpha:1,rotation:0,duration:0.4});
      gsap.to(this.moins.nativeElement, {autoAlpha:0,rotation:-90,duration:0.4, onComplete:()=>{this.closed=true;}});
    }


  }

}
