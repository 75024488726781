import { DOCUMENT } from '@angular/common';
import { GsoToTheTopComponent } from './_library/components/gso-to-the-top/gso-to-the-top.component';
import { CookieDlgComponent } from './cookie-dlg/cookie-dlg.component';
import { CookieService } from './_services/cookie.service';
import { environment } from 'src/environments/environment';
import { Router, NavigationEnd, Event } from '@angular/router';
import { LaravelService } from './_services/laravel.service';
import { Component, OnInit, ViewChild, Renderer2, OnDestroy, Inject } from '@angular/core';
import {Title} from "@angular/platform-browser";
import { filter, Subscription } from 'rxjs';
import { GsoLibService } from './_services/gso-lib-service.service';


// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/ban-types
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Make Yours';

  @ViewChild(CookieDlgComponent) cookieDlg !: CookieDlgComponent;

  @ViewChild('ttt') ttt !: GsoToTheTopComponent;


  _head:HTMLHeadElement;
  _script1:any;
  constructor(@Inject(DOCUMENT) private doc: Document,
    private libserv: GsoLibService, private renderer2:Renderer2, private cookieserv:CookieService,private router : Router,   private titleService:Title, private laSvc:LaravelService){
    this.titleService.setTitle(this.title);

  this._head = this.doc.head;

  const script1 = renderer2.createElement('link');
  // script1.type = 'media';
  script1.href = environment.rooturl+"/assets/photos/bg_600.webp"
  script1.as = 'image';
  script1.rel = 'preload';
  renderer2.setAttribute(script1, 'imagesrcset',
  environment.rooturl+"/assets/photos/bg_400SD.webp 400w,"+
  environment.rooturl+"/assets/photos/bg_600.webp 600w,"+
  environment.rooturl+"/assets/photos/bg_960.webp 960w,"+
  environment.rooturl+"/assets/photos/bg_1280.webp 1280w,"+
  environment.rooturl+"/assets/photos/bg_1920.webp 1920w,"+
  environment.rooturl+"/assets/photos/bg_3840.webp 3840w");

  //.webp 400w,  bg_600.webp 600w,  bg_960.webp 960w,  bg_1280.webp 1280w,  bg_1920.webp 1920w,  bg_3840.webp 3840w");
  this._script1=script1;
  }
  subMessageString: Subscription = new Subscription();
  subNavigationEventOnInit: Subscription = new Subscription();

  urlArray=['/studio','/methode','/solutions','/contact'];
  cookieClose=true;
  preloadCreated= false;
  // landingPage=true;
  ngOnInit(): void {
      this.laSvc.initCSRF();
     // this.laSvc.laravelLoginSPA();
     this.subNavigationEventOnInit = this.router.events.subscribe(
      (event: Event) => {
        if (event instanceof NavigationEnd) {
          if (event.url == '/accueil' || event.url == '/') {
            // ajoute le preload du background page d'accueil
            this.renderer2.appendChild(this._head, this._script1);
            this.preloadCreated=true;
          }
          else
          {
            // this.landingPage=false;
            this.unlistenerMouseMove = this.renderer2.listen("document","mousemove", ()=>this.initGTMonEvent());
            this.unlistenerScroll = this.renderer2.listen("document","scroll", ()=>this.initGTMonEvent());
            this.unlistenerTouchStart = this.renderer2.listen("document","touchstart", ()=>this.initGTMonEvent());

            if (this.preloadCreated)
            {
              this.renderer2.removeChild(this._head, this._script1);
              this.preloadCreated=false;
            }
          }
        }
      });
    // On empêche l'ouverture du popup si on ne veut pas afficher les cookies
    if (!environment.displayCookies)
    {
      this.cookieClose=true;
    }
    // Sinon on attend 10secondes avant de l'afficher
    // (dans le cas ou il faut l'afficher)
    else{
      setTimeout(()=>{
        this.cookieClose=false;
        this.cookieDlg.displayPopup()},10000)
    }

    // if (!this.landingPage)
    // {
    //   this.unlistenerMouseMove = this.renderer2.listen("document","mousemove", ()=>this.initGTMonEvent());
    //   this.unlistenerScroll = this.renderer2.listen("document","scroll", ()=>this.initGTMonEvent());
    //   this.unlistenerTouchStart = this.renderer2.listen("document","touchstart", ()=>this.initGTMonEvent());
    // }
  }
  ngOnDestroy() {
    this.subMessageString.unsubscribe();
    this.subNavigationEventOnInit.unsubscribe();
  }

  private unlistenerMouseMove!: () => void;
  private unlistenerScroll!: () => void;
  private unlistenerTouchStart!: () => void;


  initCookies()
  {
    if (environment.displayCookies)
    {
      // 1) On initialise l'environnement GA
      this.cookieserv.initCookies();

      // 2) on vérifie si l'utilisateur accepte les cookies
      if (this.cookieserv.getConsent())
        if (typeof gtag !== 'undefined')
        {
          // 3) Si oui on change la configuration de GA pour les accepter
          gtag('consent', 'update', {
            'ad_storage': 'granted',
            'analytics_storage': 'granted'
          });
        }
    }

    this.router.events.pipe(
      filter((e: Event): e is NavigationEnd => e instanceof NavigationEnd)
   ).subscribe((e: NavigationEnd) => {

    // 4) on envoie à google les évènements de changement de page
    if (environment.displayCookies)
    {
       /** START : Code to Track Page View  */
       if (typeof gtag !== 'undefined')
       gtag('event', 'page_view', {
          page_path: e.urlAfterRedirects
        })
    }
  })


  this.subMessageString = this.libserv.currentMessage.subscribe((message) => {
          // on ferme le menu
          if (message == 'hideToTheTop') this.hideToTheTop();
          if (message == 'showToTheTop') this.showToTheTop();
  });

  }

  hideToTheTop()
  {
    this.ttt.hideArrow();
  }

  showToTheTop()
  {
    this.ttt.showArrow();
  }

  initGTMonEvent()
  {
    this.initCookies();

    if (this.cookieDlg)
      this.cookieDlg.displayPopup()

      this.unlistenerScroll();
      this.unlistenerMouseMove();
      this.unlistenerTouchStart();
  }
}
