import { LaravelService } from './../../_services/laravel.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { GsoLibService } from './../../_services/gso-lib-service.service';

@Component({
  selector: 'merci',
  templateUrl: './merci.component.html',
  styleUrls: ['./merci.component.scss']
})
export class MerciComponent implements OnInit, OnDestroy {

  constructor(private libserv : GsoLibService, private laServ:LaravelService)
  {}

  ngOnDestroy(): void {

    this.libserv.createSmoother();
  }

  ngOnInit(): void {
    this.libserv.killSmoother();
  }

}
