import { GsoToTheTopComponent } from './_library/components/gso-to-the-top/gso-to-the-top.component';
import { GsoAccordeonComponent } from './_library/components/gso-accordeon/gso-accordeon.component';
import { CookieService } from './_services/cookie.service';
import { HlEffectDirective } from './hlEffect.directive';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClientXsrfModule  } from '@angular/common/http';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { DatePipe } from '@angular/common';
import { MerciComponent } from './projet/merci/merci.component';
import { CookieDlgComponent } from './cookie-dlg/cookie-dlg.component';
import { SharedModule } from './_shared/shared.module';
import { ErrorComponent } from './error/error.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HlEffectDirective,
    MenuComponent,
    MerciComponent,
    CookieDlgComponent,
    GsoAccordeonComponent,
    GsoToTheTopComponent,
    ErrorComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
		HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN',
    }),
    SharedModule
  ],
  providers: [DatePipe,
     CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
