import { gsap } from 'gsap';
import { Component, Input,  ViewChild, ElementRef, Attribute, AfterViewInit } from '@angular/core';


@Component({
  selector: 'gso-button-ripple',
  templateUrl: './gso-button-ripple.component.html',
  styleUrls: ['./gso-button-ripple.component.scss']
})
export class GsoButtonRippleComponent implements  AfterViewInit {
  @ViewChild('div') public _mondiv !:  ElementRef ;
  @ViewChild('svg') private _monsvg !:  ElementRef ;
  @ViewChild('btn') public _monbtn !:  ElementRef;
  @Input('itext') public _itext !:  string;
  @Input('ripple') public _ripple =true;

 get itext (){
   return this._itext=(!this._itext || this._itext=='')?this.text:this._itext
  }

//  svgText=" <svg class='ripple-obj'> <circle #svg cx='1' cy='1' r='1' height='100' width='100' class='ripple-circle' /> </svg>"

  constructor(

              @Attribute('text') public text: string,
              @Attribute('fontColor') public fontColor: string,
              @Attribute('borderColor') public borderColor: string,
              @Attribute('backgroundColor') public backgroundColor: string,
              @Attribute('cursor') public cursor: string,

              public ref : ElementRef) {}


  public Title="Bouton Ripple";

ngAfterViewInit()
{
  if (this.fontColor)
    this._monbtn.nativeElement.style.color=this.fontColor;

  if (this.borderColor)
    this._monbtn.nativeElement.style.borderColor=this.borderColor;

  if (this.backgroundColor)
  this._monbtn.nativeElement.style.backgroundColor=this.backgroundColor;

  if (this.cursor)
    this._monbtn.nativeElement.style.cursor=this.cursor;

}



  rippleAnimation(event:MouseEvent) {
    if (this._ripple)
    {
      const target = event.target;

      // calcule la position du curseur dans le div qui contient le bouton
      // utile dans le cas ou on met une image dans le bouton et qu'on clique sur l'image
      if (!event.currentTarget)
      return;

      let xt = event.clientX-((event.currentTarget as HTMLElement).offsetParent as HTMLElement)?.offsetLeft;
      let yt = event.clientY-((event.currentTarget as HTMLElement).offsetParent as HTMLElement)?.offsetTop;

      if (((event.currentTarget as HTMLElement).offsetTop==0) && (((event.currentTarget as HTMLElement).offsetParent as HTMLElement)?.offsetLeft==0))
        xt=event.offsetX;

      if (((event.currentTarget as HTMLElement).offsetTop==0) && (((event.currentTarget as HTMLElement).offsetParent as HTMLElement)?.offsetLeft==0))
        yt=event.offsetY;


      const x        = xt,
      y            = yt, // ,
      w            = (target as HTMLButtonElement).offsetWidth,
      h            = (target as HTMLButtonElement).offsetHeight,
      offsetX      = Math.abs( (w / 2) - x ),
      offsetY      = Math.abs( (h / 2) - y ),
      deltaX       = (w / 2) + offsetX,
      deltaY       = (h / 2) + offsetY,
      scale_ratio  = Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2));

  /*
      console.log('x is:' + x);
      console.log('y is:' + y);
      console.log('offsetX is:' + offsetX);
      console.log('offsetY is:' + offsetY);
      console.log('deltaX is:' + deltaX);
      console.log('deltaY is:' + deltaY);
      console.log('width is:' + w);
      console.log('height is:' + h);
      console.log('scale ratio is:' + scale_ratio);*/


      gsap.fromTo(this._monsvg.nativeElement,{
        x: x,
        y:y,
        transformOrigin: '50% 50%',
        scale: 0,
        opacity: 1,
        ease:"linear.in"
      },
      {
        scale: scale_ratio,
        opacity: 0,
        duration:0.75
      } );

    }
  }
}
