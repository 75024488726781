import { Router } from '@angular/router';
import { GsoLibService } from '../_services/gso-lib-service.service';
import { Component, OnInit, OnDestroy, Input, ViewChildren, QueryList, ElementRef, AfterViewInit } from '@angular/core';
import gsap from 'gsap';
import CustomEase from 'gsap/CustomEase';

@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy, AfterViewInit {
@ViewChildren('title') titles !: QueryList<ElementRef>;
  @Input('bottomright') bottomright='370.5px';
  currentTitle!:HTMLElement;
  constructor(private libserv : GsoLibService, protected router:Router) { }
  myEase=CustomEase;
  myEaseBack=CustomEase;

  ngOnInit(): void {
    gsap.registerPlugin(CustomEase);
    //strength between 0 and 1
    // CustomBounce.create("myBounce", {strength:0.7, squash:2});

    this.myEase= CustomEase.create("customCubic", ".1,.6,.4,1");
    this.myEaseBack= CustomEase.create("customCubic", "1,.4,.6,.1");
  }

  ngOnDestroy(): void {
    this.libserv.changeMessage('closeMenu');
    this.menuOpenned=false;
  }

  titleselts:ElementRef[]=[];

ngAfterViewInit(): void {

  this.titles.forEach((elt)=>this.titleselts.push(elt.nativeElement));

  gsap.set(this.titleselts, {autoAlpha: 0});


}

  onEnterTitle(evt:Event)
  {
    this.currentTitle=(evt.target as HTMLElement);
    gsap.to(this.currentTitle,{backgroundImage: "linear-gradient(280deg, #555555  0%, #FE4D18 0%)", duration:0.3, ease:this.myEase})
  }

  onLeaveTitle(evt:Event)
 {
  this.currentTitle=(evt.target as HTMLElement);
  gsap.to((evt.target as HTMLElement),{backgroundImage: "linear-gradient(280deg, #555555 100%, #FE4D18 100%)",duration:0.3, ease:this.myEaseBack})

 }


  ////////////////////////////////////////////////////
  // animation des balles devant les titres de menus
  ////////////////////////////////////////////////////
  // tlBall = gsap.timeline({delay:0});
  // onentermenutitle($event:Event)
  // {
  //   this.tlBall.kill();
  //   this.tlBall= gsap.timeline({delay:0})

  //   const rect=($event.target as HTMLElement).getBoundingClientRect();
  //   console.log(rect);
  //   let newX=rect.left-60 +'px';
  //   const newY=rect.height/2 +rect.top-10+'px';
  //   console.log(newY);

  //   gsap.set("#svgball", {x:newX, y:newY, autoAlpha:0});
  //   //gsap.set("#ball", {x:'0px', y:'0px', scaleX:1, scaleY:1,autoAlpha:0});

  //   //this.tlBall
  //   //.set("#ball", {autoAlpha:0,x:-100})
  //   newX=rect.left -30 +'px';
  //   this.tlBall.to("#svgball", {autoAlpha:1,  duration:0.5, ease:"power2.out"})
  //   this.tlBall.to("#svgball", { x:newX, duration:0.5, ease:"power2.out"}, '<')

  //   // .to("#ball", {y:120, duration: duration, ease:"myBounce"},0)
  //   // .to("#ball", {x:130, duration: duration , ease:"none"},0)
  //   // .to("#ball", {scaleY:0.8, duration: duration, scaleX:1.1, ease:"myBounce-squash", transformOrigin:"bottom"}, 0)

  // }

  // onleavemenutitle()
  //  {
  //   gsap.set("#svgball", {autoAlpha:0});
  //  }


   ////////////////////////////////////
   // Animation à l'ouverture du menu
   // basée sur la coordonnée bottomright du polygon envoyée par les fonctions appelantes
   ////////////////////////////////////
   menuOpenned=false;
   tlmenu=gsap.timeline({paused:true});

topleft = '';
btright = '';
 openmenu()
{

  const tl = gsap.timeline();
  tl.fromTo(".menuwrapper", {autoAlpha:1, clipPath:'polygon('+this.topleft+' 0px, '+this.topleft+' 0px, '+this.btright+' 100vh, '+this.btright+' 100vh)'}
  ,{clipPath: 'polygon(0px 0px, 100vw 0px, 100vw 100vh, 0px 100vh)',duration:0.7});

  return tl; // <-- IMPORTANT!!!
}


displayTitles()
{
  const tl = gsap.timeline({ease:this.myEase, defaults:{duration:0.5}})
  .set(this.titleselts, {autoAlpha:1})
  .fromTo(this.titleselts, {clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)", stagger:0.1},{clipPath:"polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)", stagger:0.1}, '0')
  return tl;
}

  showOverMenu(open=true)
  {
    const tlmaster=gsap.timeline({paused:true});
    if (!this.menuOpenned && open)
    {


      this.topleft = '370.5px';
      this.btright = this.bottomright;
      if ((this.bottomright=='0') ||(this.bottomright==''))
      {
        this.btright='310px';
        this.topleft='200px';
      }
      if (!this.btright.includes('px'))
        this.btright+='px';


      tlmaster.add(this.openmenu());
      tlmaster.add(this.displayTitles(), '-=0.2');
      tlmaster.play();

      this.libserv.changeMessage('headerBgMenu');

      this.menuOpenned=true;
    }
    else
    {
      gsap.fromTo(".menuwrapper",{clipPath: 'polygon(0px 0px, 100vw 0px, 100vw 100vh, 0px 100vh)'},
      { clipPath:'polygon('+this.topleft+' 0px, '+this.topleft+' 0px, '+this.btright+' 100vh, '+this.btright+' 100vh)',duration:0.7});
      this.libserv.changeMessage('closeMenu');
      this.menuOpenned=false;
    }
  }

}
