<div (click)="onClick()" class="acc-menuTitreLink">

  <div class="acc-menuTitre"  >{{titre}}</div>

<div class="acc-ctplusmoins" >

  <svg #plus stroke="#000" class="acc-svgplus" width="14" height="14" xmlns="http://www.w3.org/2000/svg">
    <g id="plus" >
     <title>plus</title>
     <line  stroke-linecap="undefined" stroke-linejoin="undefined" id="svg_1" y2="7" x2="14" y1="7" x1="0" fill="none" stroke="currentColor"/>
     <line stroke-linecap="undefined" stroke-linejoin="undefined" id="svg_3" y2="14" x2="7" y1="0" x1="7" fill="none" stroke="currentColor"/>
    </g>
   </svg>
   <svg #moins  stroke="#000" class="acc-svgmoins" width="16" height="16" xmlns="http://www.w3.org/2000/svg">
    <g id="moins">
     <title>moins</title>
     <line stroke-linecap="undefined" stroke-linejoin="undefined" id="svg_1" y2="7" x2="14" y1="7" x1="0" fill="none" stroke="currentColor"/>
    </g>
   </svg>
  </div>
</div>

<div #submenu class="acc-contenu">
  <div #moncontenu>
<ng-content></ng-content>
</div>
</div>
