
<span #cRetour *ngIf="tttStyle=='arrow'" class="ttt-arrowLink" (click)="remonte()">
<svg  (mouseenter)="onEnterArrow()" (mouseleave)="onLeaveArrow()"
width="24px"
height="90px"
viewBox="0 0 242.08875 1279.8831" >
 <g #arrowSvg id="arrow"
     transform="matrix(0,-0.05949591,-0.1,0,441.08872,1260)"
     fill="#000000"
     stroke="none">
   <path
       d="m 10564,4395 c -8,-20 11,-73 50,-138 l 33,-55 -35,15 c -35,14 -37,14 -55,-8 -25,-31 -20,-76 38,-364 41,-203 64,-353 80,-512 7,-73 16,-70 -118,-48 -382,61 -1407,98 -3432,125 -4380,58 -5968,49 -6650,-36 C 242,3345 0,3292 0,3270 c 0,-42 493,-70 1580,-90 3274,-60 7049,-93 8332,-71 372,6 697,14 722,17 l 46,7 v -134 c 0,-150 -11,-287 -42,-524 -11,-88 -21,-205 -23,-260 -2,-78 -7,-111 -25,-148 -24,-54 -21,-77 10,-77 34,0 95,36 290,170 608,418 861,555 1470,800 233,93 314,131 350,164 14,13 40,29 58,36 39,15 41,32 6,59 -36,28 -95,55 -382,176 -625,261 -905,417 -1477,819 -267,188 -335,223 -351,181 z m 541,-658 c 327,-253 750,-456 1123,-538 l 103,-23 -108,-23 c -241,-51 -407,-109 -658,-233 -234,-116 -405,-230 -623,-417 l -43,-36 22,54 c 35,85 85,264 105,374 25,137 25,431 1,565 -31,170 -76,305 -149,445 l -32,60 85,-79 c 46,-44 125,-111 174,-149 z"
   />
 </g>
</svg>
</span>
