import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, RendererFactory2, ViewEncapsulation, Renderer2 } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/ban-types
declare let gtag: Function;

@Injectable()
export class CookieService  {

  choosenConsent = false;

  _renderer:Renderer2;

  _head:HTMLHeadElement;
  _script1:any;
  _script2:any;
  _script3:any;

  initCookies()
  {
    if (environment.displayCookies)
    {
    this._renderer.appendChild(this._head, this._script1);
    this._renderer.appendChild(this._head, this._script2);
    this._renderer.appendChild(this._head, this._script3);
    }
  }

constructor(@Inject(DOCUMENT) private doc: Document, private rendererFactory: RendererFactory2) {
  //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
  //Add 'implements OnInit' to the class.
  const renderer = this.rendererFactory.createRenderer(this.doc, {
    id: '-1',
    encapsulation: ViewEncapsulation.None,
    styles: [],
    data: {}
  });
  this._renderer=renderer
  // 1) On lance la connexion à GA en indiquant : consentement => NON
  const script1 = renderer.createElement('script');
  script1.type = 'text/javascript';
  script1.innerHTML = 'window.dataLayer = window.dataLayer || [];'

  // Default ad_storage to 'denied' as a placeholder
          + 'function gtag(){dataLayer.push(arguments);}'
          + "    gtag('consent', 'default', {"
          + "'ad_storage': 'denied',"
          + "'analytics_storage':'denied'});";

  script1.async = true;
  script1.defer = true;


   // 2) On s'identifie auprès de Google
  const script2 = renderer.createElement('script');
  script2.type = 'text/javascript';
  script2.innerHTML = '';
  script2.src = "https://www.googletagmanager.com/gtag/js?id='"+environment.idGoogle+"'";
  script2.async = true;
  script2.defer = true;


   // 3) On configure la durée de vie des cookies pour notre environnement
  const script3 = renderer.createElement('script');
  script3.type = 'text/javascript';
  script3.innerHTML = "gtag('js', new Date());"
            + "gtag('config', '" + environment.idGoogle + "', {"
            + " cookie_expires: 31536000,"  // 12 mois
            + " cookie_update: false});";
  script3.async = true;
  script3.defer = true;

  const head = this.doc.head;


  this._head=head;
  this._script1=script1;
  this._script2=script2;
  this._script3=script3;

  // On vérifie si l'itulisateur a déjà accepté les cookies
  this.choosenConsent = localStorage.getItem('cookies_consented')=== 'true'

  // si non, on vérifie si il a choisi de refuser les cookies
  // (en variable de session pour lui redemander à chaque visite)
  if (!this.choosenConsent)
    this.choosenConsent = sessionStorage.getItem('cookies_choosen')=== 'true';
}

public getConsent(): boolean {

  return localStorage.getItem('cookies_consented') === 'true';

}

public setConsent(value: boolean): void {
  sessionStorage.setItem('cookies_choosen', 'true');
  localStorage.setItem('cookies_consented', value ? 'true' : 'false');

  let grantedOrDenied='denied';
  if (value)
    grantedOrDenied='granted'

    if (typeof gtag !== 'undefined')
  gtag('consent', 'update', {
    'ad_storage': grantedOrDenied,
    'analytics_storage': grantedOrDenied
  });
}


public gaEventEmitter(eventName: string, eventCategory: string, eventAction: string, eventLabel: string, eventValue: number) {
  if (typeof gtag !== 'undefined')
  gtag('event', eventName, {
    eventCategory,
    eventLabel,
    eventAction,
    eventValue,
  });
}

public deleteCookie(name: string) {
  this.setCookie(name, '', -1);
}

public setCookie(name: string, value: string, expireDays: number, path = '') {
  const d: Date = new Date();
  d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  const cpath = path ? `; path=${path}` : '';
  document.cookie = `${name}=${value}; ${expires}${cpath}`;
}

}
