import { Formfield } from '../_entities/FormField';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { formatDate } from '@angular/common';
import {FormGroup} from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollSmoother from 'gsap/ScrollSmoother';
import gsap from 'gsap';

@Injectable({
  providedIn: 'root'
})
export class GsoLibService {

  public CurrentTheme='';
  public  static MIN_DATE =  formatDate(new Date( new Date().getFullYear()-100,1,1), 'yyyy-MM-dd', 'en');
  public  static MAX_DATE = formatDate(new Date( new Date().getFullYear(),new Date().getMonth(),new Date().getDate()), 'yyyy-MM-dd', 'en');

  constructor() {
    gsap.registerPlugin(ScrollTrigger)
    gsap.registerPlugin(ScrollSmoother)
  }


  // utilisé pour la communication inter-composants () exemple : lancer les popup devis/contact à partir du header)
  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();

  changeMessage(message: string) {
    this.messageSource.next(message)
  }



  smoother!:ScrollSmoother|undefined;

  killSmoother()
  {
    if (this.smoother!=undefined)
    {  this.smoother.kill();
      this.smoother=undefined;
    }
  }

  createSmoother() : ScrollSmoother
  {

    if (this.smoother!=undefined)
        return this.smoother

     this.smoother = ScrollSmoother.create({
      smooth: 1, // seconds it takes to "catch up" to native scroll position
      effects: true, // look for data-speed and data-lag attributes on elements and animate accordingly
      normalizeScroll: true,
      ignoreMobileResize:true
      //smoothTouch: 0.1
    });

    return this.smoother
  }

    // convertit les retour chariot et caractère spéciaux pour envoi mail
  formatMessage(message:string) : string
  {
    message = message.replace(/\\n/g, "<br/>")
            .replace(/\\'/g, "\\'")
            .replace(/\\"/g, '\\"')
            .replace(/\\&/g, "\\&")
            .replace(/\\r/g, "\\r")
            .replace(/\\t/g, "\\t")
            .replace(/\\b/g, "\\b")
            .replace(/\\f/g, "\\f");
  // remove non-printable and other non-valid JSON chars
  // eslint-disable-next-line no-control-regex
  message = message.replace(/[\u0000-\u0019]+/g,"<br/>");
    return message;
  }
/*  replaced by sanitizeHtmlPipe !

 private transformYourHtml(htmlTextWithStyle:string|null) {
   if (htmlTextWithStyle)
    return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
    else return '';
  }*/


  // Mapping entre les noms des champs du formulaire et les noms de champs en BDD (= ceux des propriétés d'objets Entities)
  // setInitInputValue(subInitInputValues: Subscription[], myForm:FormGroup,fieldname : string, monObjet : any, monObjetPropertyName : string){
  //   if (monObjet[monObjetPropertyName]!=null)
  //   myForm.controls[fieldname].setValue(monObjet[monObjetPropertyName]);

  //   const sub= myForm.controls[fieldname].valueChanges.subscribe((newvalue) => {
  //     // if valid
  //     if (myForm.controls[fieldname].valid) {
  //        // update model
  //        monObjet[monObjetPropertyName] = newvalue;
  //     }
  //   });
  //   subInitInputValues.push(sub);
  // }

  bindFormfieldsToObjectProperties(subInitInputValues: Subscription[], myForm:FormGroup, monObjet : any, maMap:Map<string, string>){

    maMap.forEach((value,key)=>{
      if ( monObjet==null)
        console.log('Objet non defini pour la recherche de la propriété : ',value);

      else if ((myForm.controls[key]!=null) && (value in monObjet))
      {
        if (monObjet[value]!=null)
          myForm.controls[key].setValue(monObjet[value]);
        const sub= myForm.controls[key].valueChanges.subscribe((newvalue) => {
          // if valid
          if (myForm.controls[key].valid) {
            // update model
            monObjet[value] = newvalue;
            }
        });

        subInitInputValues.push(sub);
      }

      else{
        if (!(value in monObjet))
          console.log(value, ' is not a property name');
        if (myForm.controls[key]==null)
          console.log(key, ' is not a field name');
        }

      });

  }

//// FORMFIELD HELPERS ///////////
  addFieldAfter(myInputs:Formfield[],fieldToInsert:Formfield, fieldBefore:string)
  {
    const formfieldFound=myInputs.find((x)=>x.formControlName==fieldToInsert.formControlName );
    const indexfieldBefore=myInputs.findIndex((x)=>x.formControlName==fieldBefore );

    if (!formfieldFound)
      myInputs.splice(indexfieldBefore+1,0,fieldToInsert)
  }

  removeField(myInputs:Formfield[],fieldToRemove:Formfield)
  {
    const indexfieldToRemove=myInputs.findIndex((x)=>x.formControlName==fieldToRemove.formControlName );

    if (indexfieldToRemove!=-1)
      myInputs.splice(indexfieldToRemove,1);
  }

//// END FORMFIELD HELPERS ///////////

//// UPLOAD HELPERS ///////////
  getPosts(http:HttpClient) {
    const promise = new Promise((resolve, reject) => {
      http
        .get('assets/html/exemple2.html', {responseType: 'text'})
        .toPromise()
        .then(data => {
          // Success
          resolve(data);
        },
          err => {
            // Error
            console.log(err);
            reject(err);
          }
        );
    });
    return promise;

  }
//// END UPLOAD HELPERS ///////////

}
