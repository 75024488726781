<!-- arrière plan du header (polygone)-->
<div class="headerbg"></div>
<div class="headerwhite"></div>
<div class="menucontainer">
  <menu [bottomright]=menubottomright></menu>
</div>
<div #headercontainer class="headercontainer rownowrap">

  <div class="rowwrap startcenter">


    <div class="burgerContainer" (click)="onClickBurger()">
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="39.92" height="35" viewBox="0 0 216 151">
        <path id="barrejaune"
          d="M167.34,17.84a17.72,17.72,0,0,1-1.67,7.53L112,140.42A17.83,17.83,0,1,1,79.7,125.35l53.65-115a17.83,17.83,0,0,1,34,7.54Z"
          style="fill:#234e7f" />
          <!-- fbba0e -->
        <path id="barrebleue"
          d="M89.32,17.84a17.73,17.73,0,0,1-1.68,7.53L34,140.42A17.83,17.83,0,1,1,1.67,125.35l53.65-115a17.83,17.83,0,0,1,34,7.54Z"
          style="fill:#234e7f" />
          <!-- 244e80 -->
      </svg>
      <svg id="rond" version="1.0" xmlns="http://www.w3.org/2000/svg" width="39.92" height="28" viewBox="0 0 216 151">
        <path d="M215.46,130.71a20.28,20.28,0,1,1-20.28-20.27A20.28,20.28,0,0,1,215.46,130.71Z" style="fill:#ffbb00" />
        <!-- e95026 -->
      </svg>
    </div>

  </div>

  <div class="logowrapper rowwrap startcenter" >
    <div class="logocontainer">
      <div class="logomakeyourssvg" [routerLink]="['/accueil']">

        <svg xmlns="http://www.w3.org/2000/svg" zoomAndPan="magnify" height="36" viewBox="0 0 330 54.32">

          <g id="yours" style="fill: #234e7f;">
            <path
              d="m177.24,27.16v12.94c0,8.57-6.05,14.22-15.01,14.22-7.56,0-13.55-3.98-13.55-10.19h3.36c0,4.2,3.47,7.11,10.25,7.11s11.59-4.31,11.59-10.81v-3.7c-2.52,2.8-6.33,4.42-10.92,4.42-8.57,0-14.28-5.6-14.28-14v-13.16h3.36v13.1c0,6.61,4.37,10.98,10.92,10.98s10.92-4.37,10.92-10.98v-13.1h3.36v13.16Z" />
            <path
              d="m195.21,13.16c8.9,0,14.84,5.94,14.84,14.84s-5.94,14.84-14.84,14.84-14.84-5.94-14.84-14.84,5.94-14.84,14.84-14.84Zm0,26.6c6.89,0,11.48-4.7,11.48-11.76s-4.59-11.76-11.48-11.76-11.48,4.7-11.48,11.76,4.59,11.76,11.48,11.76Z" />
            <path
              d="m241.75,28c0,8.9-5.71,14.84-14.28,14.84s-14.28-5.94-14.28-14.84v-14h3.36v14c0,7.06,4.37,11.76,10.92,11.76s10.92-4.7,10.92-11.76v-14h3.36v14Z" />
            <path
              d="m248.8,28v14h-3.36v-14c0-9.24,4.65-14.84,13.05-14.84,7.06,0,12.04,4.42,12.04,10.64h-3.36c0-4.2-3.08-7.56-8.68-7.56-6.44,0-9.69,4.37-9.69,11.76Z" />
            <path
              d="m285.93,13.16c8.18,0,12.77,4.37,12.77,10.58h-3.36c0-4.2-3.3-7.5-9.41-7.5s-8.96,2.3-8.96,5.54c0,2.74,4.09,4.03,8.62,4.93,5.15,1.01,13.05,1.62,13.05,8.29,0,4.03-3.02,7.84-12.54,7.84-8.34,0-12.99-4.37-12.99-10.58h3.36c0,4.2,3.36,7.5,9.63,7.5s9.18-2.35,9.18-4.76c0-3.81-6.05-4.42-10.14-5.21-3.64-.73-11.54-2.24-11.54-8.06,0-4.09,2.97-8.57,12.32-8.57Z" />
          </g>
          <g >
            <rect id="whiterect" width="72%" height="100%" fill="white" transform="translate(-100 0)" />
            <g id="make" style="fill: #234e7f;">
              <path
                d="m19.32,42v-16.58c0-5.49-3.19-9.18-7.95-9.18s-8.01,3.7-8.01,9.18v16.58H0v-16.58c0-7.34,4.54-12.26,11.37-12.26,4.31,0,7.73,2.02,9.63,5.38,1.9-3.36,5.32-5.38,9.69-5.38,6.78,0,11.31,4.93,11.31,12.26v16.58h-3.36v-16.58c0-5.49-3.19-9.18-7.95-9.18s-8.01,3.7-8.01,9.18v16.58h-3.36Z" />
              <path
                d="m70.33,38.14c-2.52,2.97-6.33,4.7-10.92,4.7-8.57,0-14.28-5.94-14.28-14.84s5.71-14.84,14.28-14.84,14.28,5.94,14.28,14.84v14h-3.36v-3.86Zm-10.92,1.62c6.55,0,10.92-4.7,10.92-11.76s-4.37-11.76-10.92-11.76-10.92,4.7-10.92,11.76,4.37,11.76,10.92,11.76Z" />
              <path
                d="m90.05,24.47l11.76,17.53h-4.09l-10.19-15.29-6.22,5.54v9.74h-3.36V0h3.36v27.94l15.68-13.94h4.82l-11.76,10.47Z" />
              <path
                d="m106.68,29.23c.5,6.38,4.98,10.53,11.7,10.53,6.27,0,9.63-3.3,9.63-7.5h3.36c0,6.22-5.88,10.58-12.99,10.58-9.18,0-15.12-5.94-15.12-14.84s5.94-14.84,14-14.84c9.13,0,14.5,5.71,14.5,15.18v.9h-25.09Zm21.62-3.08c-.73-7-5.15-9.91-10.98-9.91s-9.91,3.92-10.58,9.91h21.56Z" />
            </g>
          </g>
        </svg>
      </div>
    </div>
  </div>

  <svg id="flechemenu" *ngIf="displayFlechemenu"
  data-name="fleche menu" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 123.14 38.03">
   <g id="SomeObjects" style="fill: #707070;">
     <path  d="m123,36.72c-2.26-3.49-4.35-7.95-7.35-10.84-.39-.37-1.15,0-.95.55,1.06,3.02,3.15,5.91,5.08,8.68-.03-.01-.06-.02-.08-.03C91.44,1.62,40.29-8.52.39,7.32c-.68.27-.4,1.37.31,1.12C22.31.71,45.7-.86,67.92,5.31c18.91,5.25,34.42,15.57,48.38,28.81-2.1-.42-4.24-.52-6.31-.13-.93.18-.67,1.57.22,1.6,3.17.09,6.03.3,8.89,1.24.26.26.53.51.79.77.37.37.8.25,1.04-.05.29.13.57.25.86.39.75.38,1.73-.42,1.22-1.22Z"/>
   </g>
 </svg>
 <img *ngIf="displayFlechemenu" id="textemenu"  src="assets/photos/menu.png" alt="texte menu">

  <div #headersmallbutton class="MysButton_go selfcenter totheright"> <gso-button-ripple bgColorLtR
      bgColor='transparent' bgColorHover='#FE4D18' fontColorHover="white" itext="Un projet ?"
      (click)="closeMenuIfOpenned()" [routerLink]="['/projet']"></gso-button-ripple></div>

  <div #headerbigbutton class="MysButton selfcenter totheright">
    <gso-button-ripple bgColorLtR bgColor='transparent' bgColorHover='#FE4D18' fontColorHover="white"
      itext="Débuter un projet" (click)="closeMenuIfOpenned()" [routerLink]="['/projet']"></gso-button-ripple>
  </div>
</div>
<div class="menutext">MENU</div>

<div class="stub"></div>

<div class="railway row startcenter ">
  <a [routerLink]="['/accueil']">Accueil</a> &nbsp;/&nbsp; <a *ngIf="railwayText!=''" [routerLink]="[railwayLink]">
    {{railwayText}} </a>
  <div class="dotscontainer row centerend totheright" [ngClass]="{'hidden': ((railwayLink=='/mentions') || (railwayLink=='/cgu')
    || (railwayLink=='/projet' || (railwayLink=='/merci')))}">
    <div class="puce" [routerLink]="['/solutions']" routerLinkActive="router-link-active"></div>
    <div class="puce" [routerLink]="['/methode']" routerLinkActive="router-link-active"></div>
    <div class="puce" [routerLink]="['/ethique']" routerLinkActive="router-link-active"></div>
    <div class="puce" [routerLink]="['/studio']" routerLinkActive="router-link-active"></div>
    <div class="puce" [routerLink]="['/contact']" routerLinkActive="router-link-active"></div>
  </div>
</div>
