import { Component, OnDestroy, ElementRef, Renderer2, ViewChild, Input } from '@angular/core';
import gsap from 'gsap';

@Component({
  selector: 'gso-to-the-top',
  templateUrl: './gso-to-the-top.component.html',
  styleUrls: ['./gso-to-the-top.component.scss']
})
export class GsoToTheTopComponent implements OnDestroy  {

  @ViewChild('cRetour') cretour !: ElementRef;
  @ViewChild('arrowSvg') arrowSvg !: ElementRef;
  @Input('scrollPositionTrigger') scrollPositionTrigger = 600;
  @Input('bottom') bottom = '50%';
  @Input('right') right = '100px';
  @Input('style') tttStyle ='arrow';

  listener:any;
   position_scroll=0;
  constructor(private renderer2: Renderer2) {
    let isVisible=false;
    this.listener = this.renderer2.listen('window', 'scroll', (e) => {
      this.position_scroll=this.getYPosition(e);

      //console.log(this.getYPosition(e));
      if (this.position_scroll>this.scrollPositionTrigger)
      {
        if (this.cretour &&  this.cretour.nativeElement && !isVisible)
        {
          const tl=gsap.timeline();
          tl.set(this.cretour.nativeElement ,{right:this.right,bottom:'calc('+this.bottom+' - 55px)',autoAlpha:0, })
          .to(this.cretour.nativeElement ,{bottom:this.bottom,autoAlpha:1, duration:0.5});
          isVisible=true;
        }
      }
      else
      {
        if (this.cretour &&  this.cretour.nativeElement && isVisible)
        {
          gsap.to(this.cretour.nativeElement ,{bottom:'calc('+this.bottom+' - 55px)',autoAlpha:0, duration:0.5});
          isVisible=false;
        }
      }
    });
  }

  hideArrow()
  {
    gsap.set(this.cretour.nativeElement ,{autoAlpha:0});
  }

  showArrow()
  {
    if (this.position_scroll>this.scrollPositionTrigger)
    {
      gsap.set(this.cretour.nativeElement ,{autoAlpha:1});
    }

  }

  getYPosition(e: Event): number {
    if (e.target)
    {
      const st=(e.target as Document);
      if (st!=null && st.scrollingElement!=null)
        return st.scrollingElement.scrollTop;
      else
        return 0;
    }
    else return 0;

  }

  ngOnDestroy(): void {
    this.listener();
  }
  remonte()
  {
    try {
      const topOfTheDoc = this.renderer2.selectRootElement('html', true);
      topOfTheDoc.scrollIntoView({behavior: "smooth"});
    } catch (err) {
      console.log ('error :', err)
    }
  }

  onEnterArrow()
{
  gsap.to(this.arrowSvg.nativeElement, {transform:"matrix(0,-0.07305745,-0.1,0,441.08872,1260)", ease:'power3', duration:0.4}  )
}
onLeaveArrow()
{
  gsap.to(this.arrowSvg.nativeElement, {transform:"matrix(0,-0.05949591,-0.1,0,441.08872,1260)", ease:'power3', duration:0.4}  )
}

}
