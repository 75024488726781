import { environment } from 'src/environments/environment';
import { CookieService } from './../_services/cookie.service';
import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';
import gsap from 'gsap';
import ScrollSmoother from 'gsap/ScrollSmoother';

@Component({
  selector: 'cookie-dlg',
  templateUrl: './cookie-dlg.component.html',
  styleUrls: ['./cookie-dlg.component.scss']
})
export class CookieDlgComponent implements OnInit, AfterViewInit {
  @ViewChild('bgcookie') bgcookie !:ElementRef;
  @ViewChild('wrappercookie') wrappercookie !:ElementRef;
  @Input('closepopup') closepopupinput=false;

  constructor(private cookieSrv:CookieService){}
  public closePopup = false;
  gaId="_ga_"+environment.idGoogle.slice(2, environment.idGoogle.length);

  displayPopup()
  {
    const smoother = ScrollSmoother.get();
    if (smoother && !this.cookieSrv.choosenConsent )
      smoother.paused(true);
    this.closePopup=this.cookieSrv.choosenConsent;
    gsap.set('#whiterect', {fill:'white'});
    if (!this.closePopup)
    gsap.set('#whiterect', {fill:'rgba(165, 165, 165, 1)'});
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    if (!this.closepopupinput)
      this.closePopup=this.cookieSrv.choosenConsent;
    else
    this.closePopup= this.closepopupinput;
    //gsap.set('.bgcookie' {autoAlpha:0})

  }

ngAfterViewInit(): void {
  if (this.bgcookie)
    gsap.set(this.bgcookie.nativeElement, {autoAlpha:0})


  if (this.wrappercookie)
    gsap.set(this.wrappercookie.nativeElement, {autoAlpha:0})

  // on affiche le panneau des cookies si l'utilisateur n'a pas fait son choix
  // ou si son choix n'est plus ni dans le session storage (non)
  // ni dans le local storage (oui)
  if ((! this.cookieSrv.choosenConsent) && (! this.cookieSrv.getConsent()))
    if (this.bgcookie && this.wrappercookie)
    gsap.to([this.wrappercookie.nativeElement, this.bgcookie.nativeElement], { autoAlpha:1, delay:2})
}

  public deny(): void {
    this.cookieSrv.choosenConsent = true;
    this.cookieSrv.deleteCookie("_ga");
    this.cookieSrv.deleteCookie("_ga_"+environment.idGoogle.slice(2, environment.idGoogle.length));
    this.cookieSrv.setConsent( false );
    const smoother = ScrollSmoother.get();
    if (smoother)
      smoother.paused(false);

    this.closePopup = true;
    gsap.set('#whiterect', {fill:'white'});
  }

  public accept(): void {

    this.cookieSrv.choosenConsent = true;
    this.cookieSrv.setConsent(true);
    this.cookieSrv.gaEventEmitter('cookiesAcceptes', 'cookie dialog', "cookie dialog" , 'accept dialog', 1);

    const smoother = ScrollSmoother.get();
    if (smoother)
      smoother.paused(false);

    this.closePopup = true;
    gsap.set('#whiterect', {fill:'white'});
  }

}
