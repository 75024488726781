import { Router } from '@angular/router';
import { Component, AfterViewInit, ElementRef, ViewChild, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('imgensemble') imgensemble !: ElementRef;
  @ViewChild('wrapperfooter') wrapperfooter !:ElementRef;

  constructor( private elt : ElementRef, private renderer2: Renderer2, private router:Router)
  {  }

  ngOnInit(): void {
   gsap.registerPlugin(ScrollTrigger);
  }

  goToTheTop()
  {
    try {
      const topOfTheDoc = this.renderer2.selectRootElement('html', true);
      topOfTheDoc.scrollIntoView({behavior: "smooth"});
    } catch (err) {
      console.log('erreur scroll : ' + err);
    }
  }


  onenterImgEnsemble()
  {
    (this.imgensemble.nativeElement as HTMLImageElement).src="../../assets/photos/ensemble2.webp";
  }

  onleaveImgEnsemble()
  {
    (this.imgensemble.nativeElement as HTMLImageElement).src="../../assets/photos/ensemble.webp";
  }

  ngOnDestroy()
  {
    if (this.tl)
    {
      if (this.tl.scrollTrigger)
        this.tl.scrollTrigger.kill();
      this.tl.kill();
    }
  }

tl !: GSAPTimeline;

setTriggerOpenDoorAnim(){
  ScrollTrigger.refresh();
  this.tl = gsap.timeline({
    scrollTrigger:{trigger:this.elt.nativeElement,
           start:'bottom bottom',
           end:()=> '+='+this.elt.nativeElement.clientHeight,
           scrub: true,
          //markers: true,
          toggleActions: "play none reverse none" //onEnter, onLeave, onEnterBack, and onLeaveBack
          },
          onStart:()=>{this.onenterImgEnsemble()},
          onComplete:()=>this.onleaveImgEnsemble()
        })
        .to(this.elt.nativeElement,{yPercent:0, ease:"linear",})
        .from(this.imgensemble.nativeElement, {y:'+=80px', autoAlpha:0.4}, '<');
}

getRouteActive(url:string) {
  // I assume your url has a forward slash already
  return this.router.url === url;
}

ngAfterViewInit(): void {
  const mm = gsap.matchMedia();

  mm.add("(min-height: "+1000+"px)",()=>{
    gsap.to(this.elt.nativeElement,{yPercent:-100,
    onComplete:()=>this.setTriggerOpenDoorAnim()
    });


        });


  mm.add("(max-height: "+this.elt.nativeElement.clientHeight+"px)",()=>{
    //gsap.set(this.elt.nativeElement,{yPercent:-100});

    this.tl = gsap.timeline({
      scrollTrigger:{trigger:this.elt.nativeElement,
             start:'top bottom',
             end:()=> '+='+this.elt.nativeElement.clientHeight,
             scrub: true,
           // markers: true,
            toggleActions: "play none reverse none" //onEnter, onLeave, onEnterBack, and onLeaveBack
            },
            onStart:()=>{this.onenterImgEnsemble()},
            onComplete:()=>this.onleaveImgEnsemble()
          })
         // .to(this.elt.nativeElement,{yPercent:0, ease:"linear",})
          .from(this.imgensemble.nativeElement, {y:'+=80px'});
        });

  ScrollTrigger.refresh()
  }

}
