import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { BgColorLtRDirective } from './../_library/components/bgColorLtR.directive';
import { NgModule } from '@angular/core';
import { GsoButtonRippleComponent } from '../_library/components/gso-button-ripple/gso-button-ripple.component';
import { FooterComponent } from '../footer/footer.component';


@NgModule({
  imports:      [ RouterModule, CommonModule ],
  declarations: [ GsoButtonRippleComponent, BgColorLtRDirective, FooterComponent  ],
  exports:      [ GsoButtonRippleComponent, BgColorLtRDirective, FooterComponent, CommonModule   ]
})
export class SharedModule { }
