import { ErrorComponent } from './error/error.component';
import { MerciComponent } from './projet/merci/merci.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const routes: Routes = [
  {
    path:'accueil',loadChildren: () =>
    import('./landing/landing.module').then(m => m.LandingModule)
  },
  {
    path:'projet',
    loadChildren: () => import('./projet/projet.module').then(m => m.ProjetModule)

  },
  {
    path:'merci',
    component: MerciComponent
  },
  {
    path:'solutions',
    loadChildren: () => import('./solutions/solutions.module').then(m => m.SolutionsModule)
  },
  {
    path:'methode',
    loadChildren: () => import('./methode/methode.module').then(m => m.MethodeModule)
  },
  {
    path:'ethique',
    loadChildren: () => import('./ethique/ethique.module').then(m => m.EthiqueModule)
  },
  {
    path:'studio',loadChildren: () =>
    import('./studio/studio.module').then(m => m.StudioModule)
  },
  {
    path:'contact',loadChildren: () =>
    import('./contact/contact.module').then(m => m.ContactModule)
  },
  {
    path:'mentions', loadChildren: () =>
    import('./mentions/mentions.module').then(m => m.MentionsModule)
  },
  {
    path:'cgu', loadChildren: () =>
    import('./cgu/cgu.module').then(m => m.CguModule)
  },
{
  path: '',
  redirectTo: '/accueil',
  pathMatch: 'full'
 },
//   { path: 'customers', loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule) },
{
  path: '**',
  component: ErrorComponent
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'disabled'})], //scrollPositionRestoration: 'enabled'
  exports: [RouterModule],
   declarations: [
  ]
})
export class AppRoutingModule {


}
