import { ConfigService } from './config.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, firstValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LaravelService {

  rootUrl = "";


  constructor( private http : HttpClient, private cfgserv:ConfigService) {
      this.rootUrl = environment.url;
  }


  monHeaders : HttpHeaders= new HttpHeaders({
    'Content-Type':  'application/json',
    'Accept': 'application/json'
    });

  httpOptions = {
    headers: this.monHeaders,
    observe:'body' as const,
    // observe:'response' as 'response',
     withCredentials: true
  };

  monHeadersFile : HttpHeaders= new HttpHeaders({
    'Accept': 'application/json'
    });


  httpOptionsFile = {
    headers: this.monHeadersFile,
    observe:'events' as const,
    reportProgress:true,
     withCredentials: true
  };

  async initCSRF():Promise<boolean>
  {
      let hasHeaders= false;
      await firstValueFrom(
        this.http.get<HttpResponse<Record<string, unknown>>>(this.rootUrl+"sanctum/csrf-cookie", { observe: 'response' }))
        .then(data => {
            //console.log(data.headers.keys());
            hasHeaders=(data.headers.keys().length>0)
          }
        );
      return hasHeaders;
  }


  async laravelLogout():Promise<string>
  {
    try
    {
      const data = await this.postData(JSON.stringify({}) , 'logout');
      sessionStorage.clear();
      return data;
    }
    catch(err:any)
    {
      console.log('err:',err);
      return err.errormessage;
    }

  }


 getData(url = "api/getjson"):Promise<string>
 {
  return new Promise<string> ((res, err) =>{
  firstValueFrom(
   this.http.get<any>(this.rootUrl+url, this.httpOptions))
  .then(
    data => {
                  res(data);
                   //console.log(data);
                   },
    error=> {//console.error('erreur : ', error)
          if (error.error.message && error.error.message!='')
            err(error.error.message);
          else
            err(error);
                    })
                  }
  );

}


  postData(jsonString:string, url='api/savejson')
  {
    const body = jsonString;
    return new Promise<string> ((res, err) =>{
      firstValueFrom(
    this.http.post<any>(this.rootUrl+url, body, this.httpOptions))
    .then(
      data => {
        res(data);
      }
      ,
    error=> {//console.error('erreur : ', error)
                      err(error);
                    }
      );
  }
  );
  }

  postFile(formData:FormData, url='api/savefile'):Observable<HttpEvent<Record<string, unknown>>>
  {
    const result =this.http.post<any>(this.rootUrl+url, formData, this.httpOptionsFile)

    return result;
  }

  async laravelPostFile(formData:FormData, url='api/savefile'):Promise<Observable<HttpEvent<any>>>
  {
   // console.log('formdata',formData.get('file'));
   let result:any;
   // login SPA
    const configJson = await this.cfgserv.get();
    result= await this.laravelLogin(configJson.laSPALogin ,configJson.laSPAPassword,  configJson.jwk)
   .catch(()=>{result= 'erreur'; console.log("erreur") })

    result =await this.http.post<any>(this.rootUrl+url, formData, this.httpOptionsFile);

    //await this.laravelLogout();
    console.log(result);
    return result;
  }


  laravelLogin(b64email='', b64password='', key:JsonWebKey)
  { return new Promise<string> ((res, err) =>{

     this.encryptCredentials(b64email, b64password,key).then((data) =>
     firstValueFrom(
      this.http.post<any>(this.rootUrl+'spalogin', {
        email:data[0],
        password: data[1],
        iv: data[2],}, this.httpOptions))
        .then( data => {
              sessionStorage.setItem('logged','ok');
              res(data['email']);
             // console.log('logged OK');
            },
            error => {
              console.log(error);
              err(error.error.message);
            }
        ));
          }
    );
  }

  laravelRegister(name='', email='', password='', password_confirmation='')
  {
    this.http.post<any>(this.rootUrl+'register', {name:name, email:email, password:password, password_confirmation:password_confirmation, }, this.httpOptions)
    .subscribe(
      { next :  () => {
          alert('ok');
          //this.setError('');

        },

        error : error => {
          console.log(error);
          alert(error.error.message);
          //this.setError(error.error.message);
        }
      });
  }


  async sendMail(obj : any) :Promise<string>
  {

    let value='erreur';

    const configJson = await this.cfgserv.get();
    // login SPA
    await this.laravelLogin(configJson.laSPALogin ,configJson.laSPAPassword,  configJson.jwk)
    .catch(()=>{value= 'erreur' })

    // envoi du mail
    await this.postData(obj, 'api/sendemails').then( data => {
      if (JSON.stringify(data)=='{"result":"ok"}')
      {
        value= 'ok';
      }
      else
      {
        value= 'erreur:'+JSON.stringify(data);
      }
  })
  .catch((err)=>{value=err.error; console.log(value) })

  // logout SPA
  .finally()
  { await this.laravelLogout(); }

  return value;
  }



async encryptCredentials(email:string, password:string,key:JsonWebKey) : Promise<string[]>
{
  const encoder = new TextEncoder();

//   const newEncryptionKey = await window.crypto.subtle.generateKey(
//     {
//         name: 'AES-GCM',
//         length: 256,
//     },
//     true,
//     ['encrypt', 'decrypt'],
// );

  const encodedPassword = encoder.encode(password);
  const encodedEmail = encoder.encode(email);
  const encryptionKey= await window.crypto.subtle.importKey(
    "jwk",
    key,
    "AES-GCM",
    true,
    ["encrypt", "decrypt"]
  );

  const iv = window.crypto.getRandomValues(new Uint8Array(12));


  // cryptage password
  const cipherPassword = await window.crypto.subtle.encrypt(
      {
          name: 'AES-GCM',
          iv,
      },
      encryptionKey,
      encodedPassword,
  );


  // cryptage email
  const cipherEmail = await window.crypto.subtle.encrypt(
    {
        name: 'AES-GCM',
        iv,
    },
    encryptionKey,
    encodedEmail,
);

  let b64iv:any;
  let b64password:any;
  let b64email:any;

  await ab2b64(iv).then((data)=>{b64iv=data})
  await ab2b64(cipherPassword).then((data)=>{b64password=data})
  await ab2b64(cipherEmail).then((data)=>{b64email=data})

 //this.laravelLogin(b64email,b64password,b64iv);
  return [b64email,b64password,b64iv];


  function ab2b64(ab:any) {
    return new Promise(res => {
        const fr = new FileReader();
        fr.onload = () => {if (fr.result)
          res(fr.result.slice((fr.result as string).indexOf(';base64,') + 8))};
        fr.readAsDataURL(new Blob([ab]));
    });
}

}


}


