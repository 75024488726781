import { GsoButtonRippleComponent } from './gso-button-ripple/gso-button-ripple.component';
import { Directive, HostListener, Input, ElementRef } from '@angular/core';
import gsap from 'gsap';
import { CustomEase } from 'gsap/all';

@Directive({
  selector: '[bgColorLtR]'
})
export class BgColorLtRDirective {
  @Input() bgColor= "";
  @Input() bgColorHover= "white";
  @Input() fontColorHover= "black";

  constructor(private elt:ElementRef, private parentComp:GsoButtonRippleComponent) { }

  initialized=false;
  elementRef=this.elt;
  ngAfterViewInit()
  {
    gsap.registerPlugin(CustomEase);
    // if (this.parentComp._monbtn.nativeElement)
    //   if (this.parentComp._monbtn.nativeElement.style.backgroundColor!='')
    //     this.bgColor = this.parentComp._monbtn.nativeElement.style.backgroundColor;
    //console.log(this.elementRef);
    const myEase= CustomEase.create("customCubic", ".1,.6,.4,1");


    if (this.parentComp._monbtn.nativeElement && this.bgColor=="")
    if (this.parentComp._monbtn.nativeElement.style.backgroundColor!='')
      this.bgColor = this.parentComp._monbtn.nativeElement.style.backgroundColor;

    else
    {
      this.bgColor= "buttonface";
    }
    gsap.set(this.parentComp._monbtn.nativeElement, {background: 'linear-gradient(280deg, '+this.bgColor+' 100%, '+this.bgColorHover+' 100%)  no-repeat left'});
    this.tl.to(this.parentComp._monbtn.nativeElement, {background: 'linear-gradient(280deg, '+this.bgColor+' 0%, '+this.bgColorHover+' 0%)  no-repeat left',
     ease:myEase, outline: 'none',color:this.fontColorHover,duration:0.6},0)
  }

  tl=gsap.timeline({paused:true, defaults: {duration:0.3}});

  @HostListener('mouseenter') onMouseEnter() {

    this.tl.play();
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.tl.reverse();
  }

}
